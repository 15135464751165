import { useEffect, useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  HomeOutlined,
  BlockOutlined,
  TransactionOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Button, theme, Breadcrumb, Flex, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import logo from '~/assets/namada-icon.svg';
import type { SearchProps } from 'antd/es/input/Search';
import routes from '~/config/routes';

const { Header, Sider, Content } = Layout;
const { Search } = Input;

const DefaultLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG, colorBgElevated, boxShadow, boxShadowSecondary },
  } = theme.useToken();
  const navigate = useNavigate();
  const [breadcrumbItems, setBreadcrumbItems] = useState([{title: 'Home'}]);
  const [currentKey, setCurrentKey] = useState(null);

  const location = useLocation();
  useEffect(() => {
    let breadcrumb = [];
    if (location.pathname === '/validators') {
      breadcrumb = [{title: 'Validators'}];
      setCurrentKey(['1']);
    } else if (location.pathname.startsWith('/validator/')) {
      breadcrumb = [{title: 'Validators'}, {title: 'Detail'}];
      setCurrentKey(['1']);
    } else if (location.pathname === '/blocks') {
      breadcrumb = [{title: 'Blocks'}];
      setCurrentKey(['2']);
    } else if (location.pathname.startsWith('/block/')) {
      breadcrumb = [{title: 'Blocks'}, {title: 'Detail'}];
      setCurrentKey(['2']);
    } else if (location.pathname === '/transactions') {
      breadcrumb = [{title: 'Transactions'}];
      setCurrentKey(['3']);
    } else if (location.pathname.startsWith('/transaction/')) {
      breadcrumb = [{title: 'Transactions'}, {title: 'Detail'}];
      setCurrentKey(['4']);
    } else if (location.pathname === '/') {
      navigate(routes.validators)
    }
    setBreadcrumbItems(breadcrumb);
  }, [location.pathname]);

  

  const handleMenuClick = (e) => {
    let breadcrumb = [];
    switch (e.key) {
      case '1':
        navigate('/validators');
        breadcrumb = ['Validators'];
        setCurrentKey(['1']);
        break;
      case '2':
        navigate('/blocks');
        breadcrumb = ['Blocks'];
        setCurrentKey(['2']);
        break;
      case '3':
        navigate('/transactions');
        breadcrumb = ['Transactions'];
        setCurrentKey(['3']);
        break;
      default:
        breadcrumb = ['Validators'];
        setCurrentKey(['1']);
        break;
    }
    setBreadcrumbItems(breadcrumb);
  };

  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    const searchData = value.trim();
    if (searchData.length > 0) {
      if (searchData.length === 64) {
        navigate(`/transaction/${searchData}`);
      } else if (searchData.length === 40) {
        navigate(`/validator/${searchData}`);
      }
      else {
        navigate(`/block/${searchData}`);
      }
    }
  };

  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ backgroundColor: colorBgContainer, boxShadow: boxShadowSecondary }}
      >
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '10px 0' }}>
          <img src={logo} width={'50%'}></img>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={currentKey}
          style={{ backgroundColor: colorBgContainer }}
          items={[
            {
              key: '1',
              icon: <UserOutlined />,
              label: 'Validators',
            },
            {
              key: '2',
              icon: <BlockOutlined />,
              label: 'Blocks',
            },
            {
              key: '3',
              icon: <TransactionOutlined />,
              label: 'Transactions',
            },
          ]}
          onClick={handleMenuClick}
        />
      </Sider>
      <Layout style={{ background: colorBgElevated }}>
        <Header style={{ padding: 0, background: colorBgContainer, height: 70, boxShadow: boxShadowSecondary }}>
          <Flex justify="space-between" align="center">
            <Flex>
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: '16px',
                  width: 64,
                  height: 64,
                }}
              />
              <Flex vertical justify="center">
                <b>shielded-expedition.234567890</b>
                <a href='https://rpc-namada.qublockchain.xyz' rel="noreferrer" target='_blank'>https://rpc-namada.qublockchain.xyz</a>
              </Flex>
            </Flex>
            <Search
              placeholder="height/ tx/ validator "
              enterButton
              style={{ maxWidth: 500, marginRight: 20 }}
              size="large"
              onSearch={onSearch}
              allowClear
            />
          </Flex>
        </Header>
        <Content style={{ margin: '0 16px', background: colorBgElevated }}>
        <Breadcrumb
          items={breadcrumbItems}
        />
          <div
            className="content-wrapper"
            style={{
              padding: 24,
              minHeight: 'calc(100vh - 140px)',
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              boxShadow: boxShadow,
            }}
          >
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
