import { Flex, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { fetchBlockByHash, fetchTransactionDetail } from '~/api/store_api.ts';

import { Link, useParams } from 'react-router-dom';
import config from '~/config';
import RawData from './RawData/RawData';
type TransactionType = {
  hash: string;
  blockId: string;
  returnCode: number;
  txType: string;
  fee: number;
  gasWanted: string;
  gasUsed: string;
  data: string;
  tx: {
    Transfer: {
      shielded: string;
    };
  };
};
type BlockType = {
  chainId: string;
  height: string;
  hash: string;
  time: string;
  proposer: string;
};

function TransactionDetail() {
  const [loading, setLoading] = useState(false);
  const [tranDetail, setTranDetail] = useState<TransactionType>({
    hash: '',
    blockId: '',
    gasWanted: '0',
    gasUsed: '0',
    returnCode: 0,
    fee: 0,
    txType: '',
    data: '',
    tx: {
      Transfer: {
        shielded: '',
      },
    },
  });
  let { tx } = useParams();
  const [block, setBlock] = useState<BlockType>({
    chainId: '',
    height: '',
    hash: '',
    time: '',
    proposer: '',
  });

  const getTranDetail = async () => {
    try {
      setLoading(true);
      const resTx = await fetchTransactionDetail(tx);
      const txData = {
        hash: resTx.hash,
        blockId: resTx.block_id,
        gasWanted: '0',
        gasUsed: '0',
        returnCode: resTx.return_code,
        fee: resTx.fee_amount_per_gas_unit ? resTx.fee_amount_per_gas_unit : 0,
        data: resTx.data,
        tx:
          resTx.tx_type === 'Decrypted' && resTx.tx && resTx.tx.Ibc
            ? {
                typeUrl: resTx.tx.Ibc.Any.type_url,
                value: [...resTx.tx.Ibc.Any.value.slice(0, 10), '...'],
              }
            : { ...resTx.tx },
        txType: resTx.tx_type,
      };
      setTranDetail(txData);
      getBlock(resTx.block_id);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getBlock = async (blockId: string) => {
    const resBlock = await fetchBlockByHash(blockId);

    const block = {
      chainId: resBlock.header.chain_id,
      height: resBlock.header.height,
      hash: resBlock.block_id,
      time: resBlock?.header.time,
      proposer: resBlock.header.proposer_address,
    };

    setBlock(block);
  };

  useEffect(() => {
    getTranDetail();
  }, [tx]);

  function generateBlockDetailPath(template, height) {
    let path = template;
    path = path.replace(`:height`, height);
    return path;
  }

  return (
    <Flex vertical gap="large">
      {loading ? (
        <>
          <div className="spin-wrapper">
            <Spin size="large" />
          </div>
        </>
      ) : !tranDetail.blockId ? (
        <>Data not found</>
      ) : (
        <>
          <div className="info-container">
            <div className="info-wrapper chain">
              <p>Chain Id</p>
              <div className="info">
                <p>{block?.chainId}</p>
              </div>
            </div>
            <div className="info-wrapper hash">
              <p>Tx Hash</p>
              <div className="info ">
                <p>{tranDetail?.hash}</p>
              </div>
            </div>
            <div className="info-wrapper">
              <p>Status</p>
              <div className="info">
                <p>{tranDetail?.returnCode === 0 || tranDetail?.txType === 'Wrapper' ? 'Success' : 'false'}</p>
              </div>
            </div>
            <div className="info-wrapper">
              <p>Height</p>
              <div className="info">
                <Link to={generateBlockDetailPath(config.routes.blockDetail, block?.height)}>{block?.height}</Link>
              </div>
            </div>
            <div className="info-wrapper">
              <p>Time</p>
              <div className="info">
                <p>{block?.time}</p>
              </div>
            </div>

            <div className="info-wrapper">
              <p>Free</p>
              <div className="info">
                <p>{tranDetail?.fee}</p>
              </div>
            </div>
            <div className="info-wrapper">
              <p>Gas (used / wanted)</p>
              <div className="info">
                <p>
                  {tranDetail.gasUsed} / {tranDetail.gasWanted}
                </p>
              </div>
            </div>
            <div className="info-wrapper">
              <p>Shielded</p>
              <div className="info">
                <p>
                  {tranDetail && tranDetail.tx && tranDetail.tx.Transfer && tranDetail.tx.Transfer.shielded
                    ? 'Yes'
                    : 'No'}
                </p>
              </div>
            </div>
          </div>
          <RawData data={tranDetail} />
        </>
      )}
    </Flex>
  );
}

export default TransactionDetail;
