// Next.js API route support: https://nextjs.org/docs/api-routes/introduction
export const convertVotingPower = (tokens) => {
  return Math.round(Number(tokens) / 10 ** 6).toLocaleString(undefined);
};

export const convertParticipation = (votingPercentage) => {
  return votingPercentage.toFixed(2) + '%'
}

export default function handler(req, res) {
  res.status(200).json({ name: 'John Doe' })
}

export async function fetchValidators(
  page: number,
  perPage: number,
  options?: RequestInit
) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_RPC_URL}/validators?page=${page}&per_page=${perPage}`,
      options
    )
    const { result } = await response.json()
    return result
  } catch (error) {
    console.error('Error:', error)
  }
}

export async function fetchLastBlock() {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/block/last`
    )
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error:', error)
  }
}

export async function fetchValidatorUptime(
  address: string,
  start: number,
  end: number
) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/validator/${address}/uptime?start=${start}&end=${end}`
    )
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error:', error)
  }
}

export async function fetchValidatorCommitSignatures(address: string) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/validator/${address}/commit_signatures`
    )
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error:', error)
  }
}

export async function fetchBlocks(
  page: number,
  perPage: number,
  options?: RequestInit
) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/block?page=${page}&page_size=${perPage}`,
      options
    )
    const res = await response.json()
    const resutl = res.data.map((item, index) => {
      return {
        no: index + 1,
        height: item.header.height,
        hash: item.block_id,
        txs: item.tx_hashes.length,
        proposer: item.header.proposer_address,
        time: item.header.time
      }
    })
    return resutl
  } catch (error) {
    console.error('Error:', error)
  }
}

export async function fetchBlockDetail(height: string) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/block/height/${height}`
    )
    const res = await response.json()
    return res
  } catch (error) {
    console.error('Error:', error)
  }
}

export async function fetchBlockByHash(hash: string) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/block/hash/${hash}`
    )
    const res = await response.json()
    return res
  } catch (error) {
    console.error('Error:', error)
  }
}

export async function fetchTransactionDetail(hash: string) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/tx/${hash}`
    )
    const res = await response.json()
    return res
  } catch (error) {
    console.error('Error:', error)
  }
}

export async function fetchTransactions(page: number, perPage: number) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/tx?page=${page}&page_size=${perPage}`
    )
    const res = await response.json()
    const result = res.data.map((item, index) => {
      return {
        no: index + 1,
        blockId: item.block_id,
        tx: item.hash,
        height: undefined,
        type: item.tx && Object.keys(item.tx).length > 0 ? Object.keys(item.tx)[0] : item.tx_type,
        shielded: item.tx && item.tx.Transfer && item.tx.Transfer.shielded ? 'Yes' : 'No',
        status: item.returnCode === 0 || item.tx_type === 'Wrapper' ? "Success" : "Fail" 
      }
    })
    return result
  } catch (error) {
    console.error('Error:', error)
  }
}

export async function fetchProposals() {
  try {
    const response = await fetch(
      'https://it.api.namada.red/api/v1/chain/governance/proposals'
    )
    const { proposals } = await response.json()

    return proposals
  } catch (error) {
    console.error('Error:', error)
  }
}

export async function fetchCurrentValidatorsList(options?: RequestInit) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_EXP_URL}/node/validators/list`,
      options
    )
    const data = await response.json()
    return data.currentValidatorsList
  } catch (error) {
    console.error('Error:', error)
  }
}

export async function fetchLatestBlocks(address: string) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_EXP_URL}/node/validators/validator/${address}/latestBlocks`
    )
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.error('An error occurred while fetching the data.', error)
    return null
  }
}

export async function fetchLatestSignatures(address: string) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_EXP_URL}/node/validators/validator/${address}/latestSignatures`
    )
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.error('An error occurred while fetching the data.', error)
    return null
  }
}

export async function fetchValidatorsWithDetails(options?: RequestInit) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_GURU_URL}/api/validators`,
      options
    );

    const data = await response.json();

    if (!Array.isArray(data.validators)) {
      throw new Error('Invalid response format');
    }
    const dataFotmated = data.validators.map((validator) => {
      return {
          validator: validator.hex_address,
          tmAddress: validator.operator_address,
          uptime: undefined,
          votingPower: convertVotingPower(validator.tokens),
          participation: convertParticipation(validator.voting_power_percent),
          commitSignatures: undefined,
          moniker: validator.moniker,
      }
    })

    return dataFotmated;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
}

export async function fetchValidator(hash: string) {
  try {
    const response = await fetch(`${process.env.REACT_APP_GURU_URL}/api/validators`);
    const data = await response.json();

    if (!Array.isArray(data.validators)) {
      throw new Error('Invalid response format');
    }

    for (const validator of data.validators) {
      if (validator.hex_address === hash) {
        const uptimeData = await fetchValidatorUptime(
          hash,
          0, // Adjust the start and end values accordingly
          1000 // Just an example for end value, adjust it accordingly
        );
        const uptime = (uptimeData.uptime * 100).toFixed(2) + '%';

        const commitSignatures = await fetchValidatorCommitSignatures(hash);

        return {
          validator: hash,
          uptime: uptime,
          votingPower: convertVotingPower(validator.tokens),
          participation: convertParticipation(validator.voting_power_percent),
          commitSignatures: commitSignatures,
          moniker: validator.moniker,
        }
      }
    }
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
}