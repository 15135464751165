import './GlobalStyles.scss'

export const tokenTheme = {
  fontFamily: "'KodeMono', sans-serif",
  borderRadius: 4,
  controlOutlineWidth: 0,
  colorPrimary: '#e8b339',
  colorTextBase: '#FFFFFF',
  colorTextPlaceholder: "#FFFFFF",
  
  colorBorder: '#ffe3a43b',
  colorBorderSecondary: '#f0f0f04d',

  colorBgBase: "#171923",
  colorBgContainer: '#2a334c4d',
  colorBgElevated: '#171923',
  colorBgSpotlight: '#171923',
  
  boxShadow: '-2px -2px 10px #2a364c, 2px 2px 2px #1e2538, inset -2px -2px 10px #2a364c, inset 2px 2px 10px #1e2538',
  boxShadowSecondary: '-2px -2px 10px #2a364c, inset -2px -2px 10px #2a364c, inset 2px 2px 10px #1e2538',
}

function GlobalStyles({children}) {
  return children
}
export default GlobalStyles