import Validators from '~/pages/Validators/index.tsx';
import ValidatorDetail from '~/pages/ValidatorDetail/index.tsx';
import config from '~/config';
import Blocks from '~/pages/Blocks/index.tsx';
import BlockDetail from '~/pages/BlockDetail/index.tsx';
import Transactions from '~/pages/Transactions/index.tsx';
import TransactionDetail from '~/pages/TransactionDetail/index.tsx';

const publicRoutes = [
    { path: config.routes.home, component: Validators },
    { path: config.routes.validators, component: Validators },
    { path: config.routes.validatorDetail, component: ValidatorDetail },
    { path: config.routes.blocks, component: Blocks },
    { path: config.routes.blockDetail, component: BlockDetail },
    { path: config.routes.transactions, component: Transactions },
    { path: config.routes.transactionDetail, component: TransactionDetail },
];

const privateRoutes = [];

export { publicRoutes, privateRoutes };
