import { GetProp, Input, Table, TableProps } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchBlocks } from '~/api/store_api.ts';
import config from '~/config';
import { useDebounce } from '~/hooks';
import { formatDistanceToNow } from 'date-fns';
import useInterval from '~/hooks/useInterval';

type ColumnsType<T> = TableProps<T>['columns'];
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface DataType {
  height: string;
  hash: string;
  txs: string;
  proposer: string;
  time: string;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

const { Search } = Input;

function generateBlockDetailPath(template, height) {
  let path = template;
  path = path.replace(`:height`, height);
  return path;
}

function generateValidatorDetailPath(template, address) {
  let path = template;
  path = path.replace(`:address`, address);
  return path;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'NO',
    dataIndex: 'no',
    key: 'no',
  },
  {
    title: 'HEIGHT',
    dataIndex: 'height',
    key: 'height',
    render: (height) => <Link to={generateBlockDetailPath(config.routes.blockDetail, height)}>{height}</Link>,
  },
  {
    title: 'HASH',
    dataIndex: 'hash',
    key: 'hash',
  },
  {
    title: 'TXS',
    dataIndex: 'txs',
    key: 'txs',
  },
  {
    title: 'PROPOSER',
    dataIndex: 'proposer',
    key: 'proposer',
    render: (proposer) => (
      <Link to={generateValidatorDetailPath(config.routes.validatorDetail, proposer)}>{proposer}</Link>
    ),
  },
  {
    title: 'TIME',
    dataIndex: 'time',
    key: 'time',
    render: (time) => <p>{formatDistanceToNow(time, { addSuffix: true })}</p>,
  },
];

function Blocks() {
  const [data, setData] = useState<DataType[]>([]);
  const [originData, setOriginData] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [searchValue, setSearchValue] = useState('');
  const debounced = useDebounce(searchValue, 500);

  var isFetchingData = false;
  const fetchData = async () => {
    try {
      if (isFetchingData) {
        return;
      }

      isFetchingData = true;
      const response = await fetchBlocks(1, 50);
      if (response) {
        const tempData = response.map((data) => {
          return {
            key: data.height,
            ...data,
          };
        });

        if(originData.length === 0){
          setOriginData(tempData);
          setData(tempData);

          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: tempData.length,
            },
          });
        } else {
          setOriginData(tempData);

          if (!isFilter) {
            setData(tempData);
          }
        }

       
      }
    } catch (error) {
      console.error(error);
    } finally {
      isFetchingData = false;
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    setLoading(true)
  }, [])

  useInterval(() => {
    fetchData();
  }, 5000);

  const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter, extra) => {
    setTableParams({
      pagination,
    });
  };

  useEffect(() => {
    filterDataBySearchInput(debounced);
  }, [debounced]);

  const filterDataBySearchInput = (searchInput) => {
    const normalizedSearchInput = searchInput.toLowerCase();

    const newData = !searchInput
      ? originData
      : originData.filter(
          ({ height, hash, proposer }) =>
            height.toLowerCase().includes(normalizedSearchInput) ||
            hash.toLowerCase().includes(normalizedSearchInput) ||
            proposer.toLowerCase().includes(normalizedSearchInput),
        );

    setData(newData);
    setTableParams((prevTableParams) => ({
      ...prevTableParams,
      pagination: {
        ...prevTableParams.pagination,
        total: newData.length,
      },
    }));
  };

  const handleChange = (e) => {
    const searchValue = e.target.value;

    if (searchValue.startsWith(' ')) {
      setIsFilter(false);
      return;
    } else if (searchValue === ''){
      setSearchValue(searchValue);
      setIsFilter(false);
    } else {
      setSearchValue(searchValue);
      setIsFilter(true);
    }
  };

  return (
    <>
      <Search
        placeholder="Search by height/hash/proposer"
        enterButton
        style={{ maxWidth: 500, height: 60 }}
        size="large"
        value={searchValue}
        onChange={handleChange}
      />
      <h2>Latest 50 blocks</h2>
      <Table
        dataSource={data}
        columns={columns}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </>
  );
}

export default Blocks;
