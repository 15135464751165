import { GetProp, Input, Table, TableProps } from 'antd';
import { formatDistanceToNow } from 'date-fns';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchBlockByHash, fetchTransactions } from '~/api/store_api.ts';
import config from '~/config';
import { useDebounce } from '~/hooks';
import useInterval from '~/hooks/useInterval';

type ColumnsType<T> = TableProps<T>['columns'];
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface DataType {
  blockId: string;
  tx: string;
  height: string;
  type: string;
  shielded: string;
  status: string;
  time: Date
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

const { Search } = Input;

function generateBlockDetailPath(template, height) {
  let path = template;
  path = path.replace(`:height`, height);
  return path;
}

function generateTransactionDetailPath(template, tx) {
  let path = template;
  path = path.replace(`:tx`, tx);
  return path;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'NO',
    dataIndex: 'index',
    key: 'index',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'TX',
    dataIndex: 'tx',
    key: 'tx',
    render: (tx) => <Link to={generateTransactionDetailPath(config.routes.transactionDetail, tx)}>{tx}</Link>,
  },
  {
    title: 'HEIGHT',
    dataIndex: 'height',
    key: 'height',
    render: (height) => <Link to={generateBlockDetailPath(config.routes.blockDetail, height)}>{height}</Link>,
  },
  {
    title: 'TYPE',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'SHIELDED',
    dataIndex: 'shielded',
    key: 'shielded',
  },
  {
    title: 'STATUS',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'TIME',
    dataIndex: 'time',
    key: 'time',
    render: (time) => {return time ? <p>{formatDistanceToNow(time || '', { addSuffix: true })}</p> : <></>},
  },
];

function Transactions() {
  const [data, setData] = useState<DataType[]>([]);
  const [originData, setOriginData] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [searchValue, setSearchValue] = useState('');
  const debounced = useDebounce(searchValue, 500);
  const [isFilter, setIsFilter] = useState(false);

  useEffect(() => {
    fetchData()
    setLoading(true)
  }, [])

  var isFetchingData = false;
  const fetchData = async () => {
    try {
      if (isFetchingData) {
        return;
      }

      isFetchingData = true;
      const response = await fetchTransactions(1, 10);
      if (response) {
        const tempData = response.map((data) => {
          return {
            key: data.tx,
            ...data,
          };
        });

        if(originData.length === 0){
          setOriginData(tempData);
          setData(tempData);

          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: tempData.length,
            },
          });
        } else {
          const itemToAdds = tempData.filter((temp) => !originData.some((origin) => origin.tx === temp.tx))
          if(itemToAdds?.length > 0){
            setOriginData(prevData => itemToAdds.concat(prevData));
            
            if (!isFilter) {
              setData(prevData => itemToAdds.concat(prevData));
            }

          }
        }

      }
    } catch (error) {
      console.error(error);
    } finally {
      isFetchingData = false;
      setLoading(false);
    }
  };

  useInterval(() => {
    fetchData();
  }, 5000);

  const getHeightOfTransaction = async () => {
    const startIndex = (tableParams.pagination.current - 1) * tableParams.pagination.pageSize;
    const endIndex = startIndex + tableParams.pagination.pageSize;
    const itemsForCurrentPage = data.slice(startIndex, endIndex);
    try {
      setLoading(true);
      const validatorsWithDetails = await Promise.all(
        itemsForCurrentPage.map(async (tran) => {
          if (!tran.height) {
            const res = await fetchBlockByHash(tran.blockId);

            return {
              ...tran,
              height: res.header.height,
              time: res.header.time
            };
          } else {
            return tran;
          }
        }),
      );

      const updatedData = data.map((item, index) => {
        if (index >= startIndex && index < endIndex) {
          return validatorsWithDetails[index - startIndex];
        }
        return item;
      });

      setData(updatedData);

      const updatedOriginData = originData.map((originItem) => {
        const detailIndex = validatorsWithDetails.findIndex(
          (detailItem) => detailItem.tx === originItem.tx,
        );
        return detailIndex !== -1 ? validatorsWithDetails[detailIndex] : originItem;
      });

      setOriginData(updatedOriginData);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const startIndex = (tableParams.pagination.current - 1) * tableParams.pagination.pageSize;
    const endIndex = startIndex + tableParams.pagination.pageSize;
    const itemsForCurrentPage = data.slice(startIndex, endIndex);

    if (itemsForCurrentPage.some((tran) => !tran.height)) {
      getHeightOfTransaction();
    }
  }, [data, tableParams.pagination.current, tableParams.pagination.pageSize]);

  const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter, extra) => {
    setTableParams({
      pagination,
    });
  };

  useEffect(() => {
    filterDataBySearchInput(debounced);
  }, [debounced]);

  const filterDataBySearchInput = (searchInput) => {
    const normalizedSearchInput = searchInput.toLowerCase();

    const newData = !searchInput
      ? originData
      : originData.filter(
          ({ tx, height }) =>
            height.toLowerCase().includes(normalizedSearchInput) || tx.toLowerCase().includes(normalizedSearchInput),
        );

    setData(newData);
    setTableParams((prevTableParams) => ({
      ...prevTableParams,
      pagination: {
        ...prevTableParams.pagination,
        total: newData.length,
      },
    }));
  };

  const handleChange = (e) => {
    const searchValue = e.target.value;

    if (searchValue.startsWith(' ')) {
      setIsFilter(false);
      return;
    } else if (searchValue === ''){
      setSearchValue(searchValue);
      setIsFilter(false);
    } else {
      setSearchValue(searchValue);
      setIsFilter(true);
    }
  };

  return (
    <>
      <Search
        placeholder="Search by tx/height"
        enterButton
        style={{ maxWidth: 500, height: 60 }}
        size="large"
        value={searchValue}
        onChange={handleChange}
      />
      <h2>Latest 10 transaction</h2>
      <Table
        dataSource={data}
        columns={columns}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </>
  );
}

export default Transactions;
