import DefaultLayout from '~/components/Layout/index.tsx';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { publicRoutes } from '~/routes';
import { ConfigProvider } from 'antd';
import { tokenTheme } from './components/GlobalStyles/index.tsx';
import React from 'react';

function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <ConfigProvider
          theme={{
            token: tokenTheme,
          }}
        >
          <Routes>
            {publicRoutes.map(({ path, layout: Layout = DefaultLayout, component: Page }) => (
              <Route
                key={path}
                path={path}
                element={
                  <Layout>
                    <Page />
                  </Layout>
                }
              />
            ))}
          </Routes>
        </ConfigProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default App;
