import { Card, Flex, Spin, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { fetchLatestSignatures, fetchValidator } from '~/api/store_api.ts';
import { AnimatePresence, motion } from 'framer-motion';
import config from '~/config';
import { Link } from 'react-router-dom';

import { useParams } from 'react-router-dom';
import useInterval from '~/hooks/useInterval';
type ValidatorType = {
  validator: string;
  uptime: string;
  votingPower: string;
  participation: string;
  commitSignatures: number;
  moniker: string;
};

function generateBlockDetailPath(template, height) {
  let path = template;
  path = path.replace(`:height`, height);
  return path;
}

function ValidatorDetail() {
  const [listBlocks, setListBlocks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validatorDetail, setValidatorDetail] = useState<ValidatorType>({
    validator: '',
    uptime: '',
    votingPower: '',
    participation: '',
    commitSignatures: 0,
    moniker: '',
  });
  let { address } = useParams();

  var isFetchingBlock = false;
  const getBlocks = async () => {
    try {
      if (isFetchingBlock) {
        return;
      }
      isFetchingBlock = true;

      const response = await fetchLatestSignatures(address);
      setListBlocks(response);
    } catch (error) {
    } finally {
      isFetchingBlock = false;
      setLoading(false);
    }
  };
  const getTransDetail = async () => {
    const response = await fetchValidator(address);
    setValidatorDetail(response);
  };
  useEffect(() => {
    getTransDetail();
  }, [address]);

  useEffect(() => {
    getBlocks();
    setLoading(true)
  }, []);

  useInterval(() => {
    getBlocks();
  }, 5000);

  return (
    <Flex vertical gap="large">
      {loading ? (
        <>
          <div className="spin-wrapper">
            <Spin size="large" />
          </div>
        </>
      ) : !validatorDetail?.moniker ? (
        <>Data not found</>
      ) : (
        <>
          <div className="info-container validator-info-container">
            <div className="info-wrapper moniker">
              <p>Moniker</p>
              <div className="info">
                <p>{validatorDetail.moniker}</p>
              </div>
            </div>
            <div className="info-wrapper address">
              <p>Address</p>
              <div className="info ">
                <p>{validatorDetail.validator}</p>
              </div>
            </div>
            <div className="info-wrapper">
              <p>Voting power</p>
              <div className="info">
                <p>{validatorDetail.votingPower}</p>
              </div>
            </div>
            <div className="info-wrapper">
              <p>Commit signatures</p>
              <div className="info">
                <p>{validatorDetail.commitSignatures}</p>
              </div>
            </div>
            <div className="info-wrapper">
              <p>Participation</p>
              <div className="info">
                <p>{validatorDetail.participation}</p>
              </div>
            </div>
            <div className="info-wrapper">
              <p>Up time</p>
              <div className="info">
                <p>{validatorDetail.uptime}</p>
              </div>
            </div>
          </div>

          <Card title={<h3 style={{ fontWeight: 'bold' }}>100 Signed Blocks</h3>}>
            {loading ? (
              <div className="spin-wrapper">
                <Spin size="large" />
              </div>
            ) : (
              <AnimatePresence>
                <Flex gap="small" wrap="wrap">
                  {listBlocks.map((block, index) => {
                    const blockClass = block.sign_status ? 'block-active' : 'block-inactive';
                    const title = (
                      <>
                        <p>Block: {block.block_number}</p>
                        <p>Signed: {block.sign_status.toString()}</p>
                      </>
                    );
                    return (
                      <motion.div
                        layout
                        initial={{ x: -10, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ opacity: 0 }}
                        key={block.block_number}
                      >
                        <Tooltip placement="top" title={title}>
                          <Link to={generateBlockDetailPath(config.routes.blockDetail, block.block_number)}>
                            <div className={`block ${blockClass} `}></div>
                          </Link>
                        </Tooltip>
                      </motion.div>
                    );
                  })}
                </Flex>
              </AnimatePresence>
            )}
          </Card>
        </>
      )}
    </Flex>
  );
}

export default ValidatorDetail;
